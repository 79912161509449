import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress, Hidden } from "@material-ui/core";

import Swal from "sweetalert2";
import AdminLayout from "../AdminLayout";
import AddIcon from "@material-ui/icons/Add";
import {
  AppBar,
  Dialog,
  IconButton,
  Rating,
  Slide,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { generateForm } from "src/helpers/functions";
import SingleCustomAutocomplete from "src/Components/CustomAutocomplete/SingleCustomAutocomplete";
import dayjs from "dayjs";
import CustomFilter from "./CustomFilter";
import CustomToolbarFilter from "src/Components/CustomToolbar/CustomToolbarFilter";
import { useLocation } from "react-router-dom";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";

const AdminJobs = () => {
  const location = useLocation();
  const { id: state_id, type: state_type } = location.state ?? {};

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(-1);
  const [paymentType, setPaymentType] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [statusVisible, setStatusVisible] = useState(false);

  const [paymentsData, setPaymentsData] = useState([]);
  const [paymentsVisible, setPaymentsVisible] = useState(false);

  const [addUpdatePaymentVisible, setAddUpdatePaymentVisible] = useState(false);

  const [filterForm, setFilterForm] = useState({
    fromDate: null,
    toDate: null,
  });

  const handleclear = () => {
    setFilterForm({
      fromDate: null,
      toDate: null,
    });
  };

  const handleCloseStatusModal = () => {
    setStatusVisible(false);
    getData();
  };

  const handleClosePaymentsModal = () => {
    setPaymentsVisible(false);
    getData();
  };
  const loadStatuses = (meta) => {
    const metaId = meta ? meta.rowData[0] : id;
    setId(metaId);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/jobs/getStatuses/${metaId}`,
    })
      .then((response) => {
        setStatusData(response.data);
        if (meta) setStatusVisible(true);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const loadPayments = (meta, type) => {
    const metaId = meta ? meta.rowData[0] : id;
    setId(metaId);
    setPaymentType(type);
    axios
      .get(`${process.env.REACT_APP_API}/admin/jobs/getPayments`, {
        params: {
          id: metaId,
          type: type,
        },
      })
      .then((response) => {
        setPaymentsData(response.data);
        if (meta) setPaymentsVisible(true);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const handleChangeInputFilter = (event) => {
    const { id, value, checked, type } = event.target;
    if (type == "checkbox") {
      setFilterForm((prev) => ({
        ...prev,
        [id]: checked,
      }));
    } else {
      setFilterForm((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const [venders, setVenders] = useState([]);
  const [customers, setCustomers] = useState([]);

  const getVenders = () => {
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getVenders`, {})
      .then(function (response) {
        setVenders(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCustomers = () => {
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getCustomers`, {})
      .then(function (response) {
        setCustomers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/jobs/get`, {
        params: {
          fromDate: filterForm.fromDate,
          toDate: filterForm,
        },
      })
      .then(function (response) {
        setData(response.data);

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  const [updateJobLoading, setUpdateJobLoading] = useState(false);

  const saveJobForm = () => {
    setUpdateJobLoading(true);
    if (jobFormData.id > 0) {
      const submitFormData = generateForm(jobFormData);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/jobs/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setUpdateJobLoading(false);
          setUpdateJobVisible(false);
          setVisible(false);
          getData();
        })
        .catch((error) => {
          setUpdateJobLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(jobFormData);

      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/jobs/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setUpdateJobLoading(false);
          setUpdateJobVisible(false);

          getData();
        })
        .catch((error) => {
          setUpdateJobLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const [updateJobVisible, setUpdateJobVisible] = useState(false);
  const [jobId, setJobId] = useState(state_id ? state_id : -1);

  const [jobFormData, setJobFormData] = useState({
    user_id: null,
    customer_id: null,
    priority: null,
    description: "",
    review_rate: "",
    review_text: "",
    // testimonial: 0,
    start_date: null,
    end_date: null,
    review: 0,
    id: -1,
  });

  const [clientPayments, setClientPayments] = useState([]);
  const [wrorkerPayments, setWorkerPayments] = useState([]);
  const resetJobForm = () => {
    setJobFormData({
      user_id: null,
      customer_id: null,
      priority: null,
      description: "",
      review_rate: "",
      review_text: "",
      // testimonial: 0,
      start_date: null,
      end_date: null,
      review: 0,
      id: -1,
    });
  };

  const handleCloseUpdateJob = () => {
    setUpdateJobVisible(false);
  };

  const addJob = () => {
    resetJobForm();
    setUpdateJobVisible(true);
  };
  const [withPayments, setWithPayments] = useState(false);
  const [tableData, setTableData] = useState([]);
  const updateJob = (meta, payments = false) => {
    setWithPayments(payments);
    resetJobForm();
    const id = meta.rowData[0];
    setJobId(id);

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/jobs/getById/${id}`,
    })
      .then((response) => {
        loadAllPayments(response.data.id, "in");
        loadAllPayments(response.data.id, "out");

        setJobFormData({
          user_id: response.data.user_id,
          customer_id: response.data.customer_id,
          priority: response.data.priority,
          description: response.data.description,
          review_rate: response.data.review_rate,
          review_text: response.data.review_text,
          // testimonial: response.data.testimonial,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          review: response.data.review,

          client_amount: response.data.client_amount,

          worker_amount: response.data.worker_amount,

          id: response.data.id,
        });

        setUpdateJobVisible(true);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const updateColor = (meta, updateValue) => {
    const id = meta.rowData[0];  
    setJobId(id);
  
    // Show confirmation dialog
    Swal.fire({
      title: "Please Confirm Your Action !",
      confirmButtonText: "Apply",
      showCancelButton: true,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API}/admin/jobs/updatePriority/${id}`)
          .then((response) => {
            const newPriority = response.data.priority;
  
            let backgroundColor = "transparent";  
            if (newPriority === 1) {
              backgroundColor = "red";
            } else if (newPriority === 2) {
              backgroundColor = "yellow";
            }
  
            updateValue(newPriority);
  
            setTableData((prevData) =>
              prevData.map((row) =>
                row.id === id
                  ? { ...row, priority: newPriority, backgroundColor } 
                  : row
              )
            );
  
            toast.success("Priority updated successfully");
          })
          .catch(() => {
            toast.error("Error updating priority");
          });
      }
    });
  };
  

  const loadAllPayments = (metaId, type) => {
    if (!metaId) metaId = jobId;
    axios
      .get(`${process.env.REACT_APP_API}/admin/jobs/getPayments`, {
        params: {
          id: metaId,
          type: type,
        },
      })
      .then((response) => {
        if (type == "in") {
          setClientPayments(response.data);
        } else {
          setWorkerPayments(response.data);
        }
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };
  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const handleClose = () => {
    setVisible(false);
    loadStatuses(null);
  };

  const handleCloseAddUpdatePayment = () => {
    if (withPayments) {
      loadAllPayments(null, "in");
      loadAllPayments(null, "out");
    } else {
      loadPayments(null, paymentType);
    }
    setPaymentId(-1);
    setAddUpdatePaymentVisible(false);
  };

  const toggleTestimonial = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "YES",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/testimonial`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API}/noAuthApi/getCategories`)
      .then(function (response) {
        const all = response.data;

        setCategories(all);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [visibleWorker, setVisibleWorker] = useState(false);
  const [formData, setFormData] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedcategories, setselectedCategories] = useState([]);

  const handleCloseWorker = () => {
    setVisibleWorker(false);
  };

  const checkWorker = (userId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/users/getById/${userId}`,
    })
      .then((response) => {
        const categoriesArr = response.data.categories.split(",");

        const selectedcategoriesArr = [];
        categoriesArr?.map((item) => {
          let found = categories.find(
            (data) => parseInt(data.category.id) == parseInt(item)
          );
          if (found) {
            selectedcategoriesArr.push(found.category);
          }
        });
        console.log(
          "selectedcategoriesArr",
          categoriesArr,
          selectedcategoriesArr
        );
        setselectedCategories(selectedcategoriesArr);

        setFormData(response.data);

        setVisibleWorker(true);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },

    {
      name: "user_id",
      label: "Worker Id",
      options: {
        display: false,
      },
    },

    {
      name: "user_name",
      label: "Worker name",

      options: {
        customBodyRender: (value, meta) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => checkWorker(meta.rowData[1])}
            >
              {" "}
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "customer_name",
      label: "Customer name",
    },
    {
      name: "customer_phone",
      label: "Customer Phone",
    },
    {
      name: "customer_email",
      label: "Customer Email",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "status_name",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <span>{value ? value : "pending..."}</span>;
        },
      },
    },
    {
      name: "priority",
      label: "priority",
    },
    {
      name: "status_description",
      label: "Note",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const priority = parseInt(tableMeta.rowData[8], 10);
          let backgroundColor = "transparent";

          if (priority === 1) {
            backgroundColor = "red";
          } else if (priority === 2) {
            backgroundColor = "yellow";
          }

          return (
            <span
              style={{
                backgroundColor,
                padding: "5px",
                borderRadius: "3px",
                display: "inline-block",
                minWidth: "50%",
                minHeight: "40px",
                cursor:
                  priority === 1 || priority === 2 ? "pointer" : "default",
              }}
              onClick={() => {
                if (priority === 1 || priority === 2) {
                  updateColor(tableMeta, updateValue);
                }
              }}
            >
              {value || "             "}{" "}
            </span>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date",
    },
    {
      name: "paidClientAmount",
      label: "paidClientAmount",
      options: {
        display: false,
      },
    },
    {
      name: "client_amount",
      label: "From Client",

      options: {
        customBodyRender: (value, meta) => (
          // <button
          //   className="btn btn-info mx-1"
          //   onClick={() => {
          //     loadPayments(meta, "in");
          //   }}
          // >
          <span> {`${meta.rowData[10] ?? 0}/${value ?? 0}`}</span>

          // </button>
        ),
      },
    },
    {
      name: "paidWorkerAmount",
      label: "paidWorkerAmount",
      options: {
        display: false,
      },
    },
    {
      name: "worker_amount",
      label: "To Worker",
      options: {
        customBodyRender: (value, meta) => (
          // <button
          //   className="btn btn-info mx-1"
          //   onClick={() => {
          //     loadPayments(meta, "out");
          //   }}
          // > {/* </button> */}
          <span> {`${meta.rowData[12] ?? 0}/${value ?? 0}`} </span>
        ),
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div className="gap-2 d-flex justify-content-center">
              <button
                className="btn btn-secondary mx-1"
                onClick={() => {
                  updateJob(meta, true);
                }}
              >
                Payments
              </button>

              <button
                className="btn btn-warning mx-1"
                onClick={() => {
                  loadStatuses(meta);
                }}
              >
                Status
              </button>

              {/* {meta.rowData[8] == 0 ? (
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    toggleTestimonial(meta);
                  }}
                >
                  Show
                </button>
              ) : (
                <button
                  className="btn btn-danger mx-1"
                  onClick={() => {
                    toggleTestimonial(meta);
                  }}
                >
                  Hide
                </button>
              )} */}

              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateJob(meta);
                }}
              >
                Update
              </button>
            </div>
          );
        },
      },
    },

    // {
    //   name: "modify",
    //   label: "Modify",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: (value, meta) => {
    //       return (
    //         <div style={{width: "100%"}}>
    //           <button
    //             className="btn btn-primary mx-1"
    //             onClick={() => {
    //               updateJob(meta);
    //             }}
    //           >
    //             Update
    //           </button>

    //           {/*
    //           <button
    //             className="btn btn-danger mx-1"
    //             onClick={() => {
    //               deleteForm(meta);
    //             }}
    //           >
    //             Delete
    //           </button> */}
    //         </div>
    //       );
    //     },
    //   },
    // },

    {
      name: "reviewed",
      label: "Reviewed",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },

    {
      name: "review_rate",
      label: "Rating",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Rating size="large" id="stars" readOnly value={value} />
          ) : (
            <></>
          );
        },
      },
    },

    {
      name: "review_text",
      label: "Review",
    },

    {
      name: "review",
      label: "Show in Reviews",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },

    // {
    //   name: "testimonial",
    //   label: "Show in Testimonials",
    //   options: {
    //     customBodyRender: (value) => {
    //       return (
    //         <input
    //           disabled
    //           className="form-check-input"
    //           type="checkbox"
    //           readOnly
    //           checked={value == 1 ? true : false}
    //         />
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    tableBodyHeight: "75vh",
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    draggableColumns: {
      enabled: true, // Enable column reordering
    },
    customToolbar: () => {
      return (
        <span>
          <CustomToolbarFilter filterListener={filterListener} />

          <Tooltip title={"Add"}>
            <IconButton onClick={addJob}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  useEffect(() => {
    getData();

    if (venders.length == 0) getVenders();
    if (customers.length == 0) getCustomers();
    if (categories.length == 0) getCategories();

    if (state_id && state_type) {
      let meta = { rowData: [state_id] };

      if (state_type == "job") {
        updateJob(meta);
      }

      if (state_type == "statuses") {
        loadStatuses(meta);
      }

      if (state_type == "payments") {
        loadPayments(meta);
      }
    }
  }, []);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const deleteStatus = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/status/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            loadStatuses(null);
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const deletePayment = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/payments/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            loadStatuses(null);
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const [paymentId, setPaymentId] = useState(-1);

  const updatePayment = (meta) => {
    resetJobForm();
    const id = meta.rowData[0];
    setPaymentId(id);

    setAddUpdatePaymentVisible(true);
  };

  const statusColumns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "date",
      label: "Follow Up Date",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "created_at",
      label: "Created",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              {/* <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  // updateStatus(meta);
                }}
              >
                Update
              </button> */}
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteStatus(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];
  const payments = ["Cash", "Whish", "OMT", "Bank Transfer"];
  const paymentsColumns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "due_date",
      label: "Date",
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <>{value == "in" ? "From Client" : "To Worker"}</>
          ) : (
            <></>
          );
        },
      },
    },

    {
      name: "paid",
      label: "paid",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "paid",
      label: "paid",
      options: {
        customBodyRender: (value) => (value ? payments[value - 1] : ""),
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-info mx-1"
                onClick={() => {
                  updatePayment(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deletePayment(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];
  const addStatus = () => {
    setVisible(true);
  };

  const addPayment = (type = null) => {
    setPaymentType(null);
    setPaymentId(-1);

    setAddUpdatePaymentVisible(true);
  };

  const statusOptions = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",

    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addStatus}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  const paymentsOptions = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",

    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addPayment}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  const ClientPaymentsOptions = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",

    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={() => addPayment("in")}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  const workerPaymentsOptions = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",

    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={() => addPayment("out")}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  const handleJobInputChange = (e) => {
    e.preventDefault();

    let { id, value, innerText, checked, type } = e.target;
    value = id.includes("option") ? innerText : value;
    id = id.includes("mui") ? "review_rate" : id;

    if (type === "checkbox") {
      setJobFormData((prev) => ({
        ...prev,
        [id]: checked ? 1 : 0,
      }));
    } else {
      setJobFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };
  const customJobOnChange = (newValue, name) => {
    setJobFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  const StatusAddUpdateForm = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [formData, setFormData] = useState({
      job_id: id,
      status_id: null,
      date: "",
      description: "",
      id: -1,
    });
    const resetForm = () => {
      setFormData({
        job_id: id,
        status_id: null,
        date: "",
        description: "",
        id: -1,
      });
    };

    const getStatuses = () => {
      axios
        .get(`${process.env.REACT_APP_API}/admin/statuses/get`, {})
        .then(function (response) {
          setStatuses(response.data);
        })
        .catch(function (error) {
          toast("Error", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.log(error);
        });
    };
    const customOnChange = (newValue, name) => {
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    };

    const handleInputChange = (e) => {
      e.preventDefault();
      const {
        id,

        value,
        checked,
        type,
      } = e.target;
      if (type === "checkbox") {
        console.log(id, value, checked);

        setFormData({
          ...formData,
          [id]: checked,
        });
      } else {
        setFormData({
          ...formData,
          [id]: value,
        });
      }
    };

    const saveForm = () => {
      setSaveIsLoading(true);
      if (formData.id > 0) {
        const submitFormData = generateForm(formData);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/status/update`,
          data: submitFormData,
        })
          .then((response) => {
            toast.success(`Updated successfully`);
            setSaveIsLoading(false);
            setVisible(false);
            props.handleClose();
          })
          .catch((error) => {
            setSaveIsLoading(false);
            toast.error(`Error While Saving`);
          });
      } else {
        const submitFormData = generateForm(formData);

        axios({
          method: "post",
          accept: "any",
          url: `${process.env.REACT_APP_API}/admin/jobs/status/add`,
          data: submitFormData,
        })
          .then((response) => {
            toast.success(`Inserted successfully`);
            setSaveIsLoading(false);
            setVisible(false);
            props.handleClose();
          })
          .catch((error) => {
            setSaveIsLoading(false);
            toast.error(`Error While Saving`);
          });
      }
    };

    useEffect(() => {
      resetForm();

      if (statuses.length == 0 && visible) getStatuses();
    }, []);
    return (
      <Dialog
        fullScreen
        open={visible}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        style={{ zIndex: 2235 }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <button
              type="button"
              className="close-modal-button"
              onClick={props.handleClose}
            >
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">
              {formData.id > 0 ? "Update" : "Add"}
            </h4>
          </Toolbar>
        </AppBar>

        <div style={{ padding: "25px" }}>
          <div className="row">
            <div className={"col-sm-6"}>
              <SingleCustomAutocomplete
                filedName="status_id"
                label="Status"
                list={statuses}
                value={formData.status_id}
                listKey="id"
                description="name"
                customOnChange={customOnChange}
              />
            </div>

            <div className={"col-sm-6"}>
              <div className="form-group">
                <div className="dateView">
                  <label
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    htmlFor="date"
                  >
                    Follow up Date
                  </label>
                  <input
                    type="date"
                    style={{
                      width: 200,
                      height: 50,
                      border: "1px solid #D3D3D3",
                      borderRadius: 5,
                    }}
                    id="date"
                    value={formData.date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className={"col-sm-12"}>
              <div className="form-group">
                <label htmlFor="name">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  onChange={handleInputChange}
                  value={formData.description}
                />
              </div>
            </div>

            <div className={"col-sm-12 mt-5"}>
              {saveIsLoading ? (
                <CircularProgress />
              ) : (
                <button
                  className="btn btn-md btn-primary float-end"
                  onClick={saveForm}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const PaymentAddUpdateForm = (props) => {
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    console.log("props.jobId");
    console.log(props.jobId);

    const [formData, setFormData] = useState({
      job_id: jobId,
      amount: null,
      due_date: "",
      paid: false,
      type: paymentType,
      method: "",
      id: -1,
    });
    const resetForm = () => {
      setFormData({
        job_id: jobId,
        amount: null,
        due_date: "",
        paid: false,
        type: paymentType,
        method: "",
        id: -1,
      });
    };

    const customOnChange = (newValue, name) => {
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    };

    const customJobPaymentOnChange = (newValue, name) => {
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    };
    const handleInputChange = (e) => {
      e.preventDefault();
      const {
        id,

        value,
        checked,
        type,
      } = e.target;
      if (type === "checkbox") {
        setFormData({
          ...formData,
          [id]: checked,
        });
      } else {
        setFormData({
          ...formData,
          [id]: value,
        });
      }
    };

    const getData = () => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/admin/jobs/payments/getById/${props.id}`,
      })
        .then((response) => {
          setFormData({
            job_id: id,
            amount: response.data.amount,
            due_date: response.data.due_date,
            method: response.data.method,
            paid: response.data.paid == 1 ? true : false,
            type: response.data.type,
            id: response.data.id,
          });
        })
        .catch((error) => {
          toast.error(`Error`);
        });
    };

    const saveForm = () => {
      setSaveIsLoading(true);
      if (formData.id > 0) {
        const submitFormData = generateForm(formData);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/jobs/updatePayment`,
          data: submitFormData,
        })
          .then((response) => {
            toast.success(`Updated successfully`);
            setSaveIsLoading(false);
            setVisible(false);
            props.handleClose();
          })
          .catch((error) => {
            setSaveIsLoading(false);
            toast.error(`Error While Saving`);
          });
      } else {
        const submitFormData = generateForm(formData);

        axios({
          method: "post",
          accept: "any",
          url: `${process.env.REACT_APP_API}/admin/jobs/payments/add`,
          data: submitFormData,
        })
          .then((response) => {
            toast.success(`Inserted successfully`);
            setSaveIsLoading(false);
            setVisible(false);
            props.handleClose();
          })
          .catch((error) => {
            setSaveIsLoading(false);
            toast.error(`Error While Saving`);
          });
      }
    };

    useEffect(() => {
      if (props.id > 0) {
        if (addUpdatePaymentVisible) getData();
      } else {
        resetForm();
      }
    }, []);
    return (
      <Dialog
        fullScreen
        open={addUpdatePaymentVisible}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        style={{ zIndex: 2235 }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <button
              type="button"
              className="close-modal-button"
              onClick={props.handleClose}
            >
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">
              {formData.id > 0 ? "Update" : "Add"}
            </h4>
          </Toolbar>
        </AppBar>

        <div style={{ padding: "25px" }}>
          <div className="row">
            <div className={"col-sm-6"}>
              <div className="form-group">
                <label htmlFor="name">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="amount"
                  onChange={handleInputChange}
                  value={formData.amount}
                />
              </div>
            </div>

            <div className={"col-sm-6"}>
              <div className="form-group">
                <div className="dateView">
                  <label
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    htmlFor="due_date"
                  >
                    Due Date
                  </label>
                  <input
                    type="date"
                    style={{
                      width: 200,
                      height: 50,
                      border: "1px solid #D3D3D3",
                      borderRadius: 5,
                    }}
                    id="due_date"
                    value={formData.due_date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className={"col-sm-6"}>
              <SingleCustomAutocomplete
                disabled={paymentType}
                filedName="type"
                label="Type"
                list={[
                  {
                    id: "in",
                    name: "From Client",
                  },
                  {
                    id: "out",
                    name: "To worker",
                  },
                ]}
                value={formData.type}
                listKey="id"
                description="name"
                customOnChange={customJobPaymentOnChange}
              />
            </div>
            <div style={{ padding: "25px" }}>
              <div className="row">
                <div className={"col-sm-6"}>
                  <SingleCustomAutocomplete
                    filedName="method"
                    label="Method of Payment"
                    list={[
                      {
                        id: 1,
                        name: "Cash",
                      },
                      {
                        id: 2,
                        name: "Whish",
                      },

                      {
                        id: 3,
                        name: "OMT",
                      },

                      {
                        id: 4,
                        name: "Bank Transfer",
                      },
                    ]}
                    value={formData.method}
                    listKey="id"
                    description="name"
                    customOnChange={customJobPaymentOnChange}
                  />
                </div>

                <div className={"col-sm-6"}>
                  <div className="form-group" style={{ padding: "20px" }}>
                    <input
                      className="form-control-checkbox"
                      id="paid"
                      type="checkbox"
                      checked={formData.paid}
                      onChange={handleInputChange}
                    />
                    <label style={{ paddingLeft: 10 }} htmlFor="paid">
                      Paid
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className={"col-sm-12 mt-5"}>
              {saveIsLoading ? (
                <CircularProgress />
              ) : (
                <button
                  className="btn btn-md btn-primary float-end"
                  onClick={saveForm}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    );
  };
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const handleCloseFilter = () => {
    setOpenFilterDialog(false);
  };

  const filterListener = () => {
    setOpenFilterDialog(true);
  };
  const handleSaveFilter = () => {
    getData();

    handleCloseFilter();
  };

  const updatedVenders = venders.map((vender) => ({
    ...vender,
    description: `${vender.first_name} ${vender.last_name}`,
  }));
  const updatedCustomers = customers.map((vender) => ({
    ...vender,
    description: `${vender.first_name} ${vender.last_name}`,
  }));

  const priorityList = [
    { id: 1, name: "No contact yet" },
    { id: 2, name: "Waiting for client response" },
    { id: 3, name: "Completed" },
  ];

  const selectedPriority = priorityList.find(
    (priority) => priority.id === jobFormData.priority
  );

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable
          title={"Jobs"}
          data={data}
          columns={columns}
          options={options}
        />

        <Dialog
          fullScreen
          open={updateJobVisible}
          onClose={handleCloseUpdateJob}
          // TransitionComponent={Transition}
          style={{ zIndex: 2235 }}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button
                type="button"
                className="close-modal-button"
                onClick={handleCloseUpdateJob}
              >
                &times;
              </button>
              {withPayments ? (
                <h4 className="text-white mx-3 my-0">Payments</h4>
              ) : (
                <h4 className="text-white mx-3 my-0">
                  {jobFormData.id > 0 ? "Update" : "Add"}
                </h4>
              )}
            </Toolbar>
          </AppBar>

          <div style={{ padding: "25px" }}>
            {!withPayments && (
              <div className="row">
                <div className={"col-sm-6"}>
                  <SingleCustomAutocomplete
                    filedName="user_id"
                    label="Worker"
                    list={updatedVenders}
                    value={jobFormData.user_id}
                    listKey="id"
                    description="description"
                    customOnChange={customJobOnChange}
                  />
                </div>
                <div className={"col-sm-6"}>
                  <SingleCustomAutocomplete
                    filedName="customer_id"
                    label="Customer"
                    list={updatedCustomers}
                    value={jobFormData.customer_id}
                    listKey="id"
                    description="description"
                    customOnChange={customJobOnChange}
                  />
                </div>
              </div>
            )}

            {!withPayments && (
              <div className="row">
                <div className={"col-sm-6"}>
                  <div className="form-group">
                    <div>
                      <label htmlFor="client_amount">
                        From Client Total amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="client_amount"
                        onChange={handleJobInputChange}
                        value={jobFormData.client_amount}
                      />
                    </div>
                  </div>
                </div>

                <div className={"col-sm-6"}>
                  <div className="form-group">
                    <div>
                      <label htmlFor="worker_amount">
                        To Worker Total amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="worker_amount"
                        onChange={handleJobInputChange}
                        value={jobFormData.worker_amount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <AppBar className="position-relative">
          <Toolbar>
            <button type="button" className="close-modal-button" onClick={handleClosePaymentsModal}>
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">Payments</h4>
          </Toolbar>
        </AppBar>
        <div style={{padding: "25px"}}>
          <CustomMuiDataTable
            title={"Payments"}
            data={paymentsData}
            columns={paymentsColumns}
            options={paymentsOptions}
          />
        </div> */}

            <div className="row">
              <div className={"col-sm-12"}>
                <div className="form-group">
                  <div>
                    <label htmlFor="description">description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      onChange={handleJobInputChange}
                      value={jobFormData.description}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-sm-12 col-md-6"}>
                <div className="form-group">
                  <div className="dateView">
                    <label
                      style={{
                        fontSize: "18px",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                      htmlFor="fromDate"
                    >
                      Start Date
                    </label>
                    <input
                      type="date"
                      style={{
                        width: 200,
                        height: 50,
                        border: "1px solid #D3D3D3",
                        borderRadius: 5,
                      }}
                      id="start_date"
                      value={jobFormData.start_date}
                      onChange={handleJobInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-sm-12 col-md-6"}>
                <div className="form-group">
                  <div className="dateView">
                    <label
                      style={{
                        fontSize: "18px",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                      htmlFor="fromDate"
                    >
                      End Date
                    </label>
                    <input
                      type="date"
                      style={{
                        width: 200,
                        height: 50,
                        border: "1px solid #D3D3D3",
                        borderRadius: 5,
                      }}
                      id="end_date"
                      value={jobFormData.end_date}
                      onChange={handleJobInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {withPayments && (
              <div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className={"col-sm-12"}>
                    <div className="form-group">
                      <div>
                        <label htmlFor="client_amount">
                          From Client Total amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="client_amount"
                          onChange={handleJobInputChange}
                          value={jobFormData.client_amount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CustomMuiDataTable
                    title={"Client Payments"}
                    data={clientPayments}
                    columns={paymentsColumns}
                    options={ClientPaymentsOptions}
                  />
                </div>

                <div className="row" style={{ marginTop: 20 }}>
                  <div className={"col-sm-12"}>
                    <div className="form-group">
                      <div>
                        <label htmlFor="worker_amount">
                          To Worker Total amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="worker_amount"
                          onChange={handleJobInputChange}
                          value={jobFormData.worker_amount}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <CustomMuiDataTable
                    title={"Worker Payments"}
                    data={wrorkerPayments}
                    columns={paymentsColumns}
                    options={workerPaymentsOptions}
                  />
                </div>
              </div>
            )}

            {!withPayments && (
              <div className="row" style={{ paddingTop: 50 }}>
                <div className={"col-sm-6"}>
                  <label htmlFor="review_text">Review</label>
                  <input
                    type="text"
                    className="form-control"
                    id="review_text"
                    onChange={handleJobInputChange}
                    value={jobFormData.review_text}
                  />
                </div>
                <div className={"col-sm-6"}>
                  <SingleCustomAutocomplete
                    filedName="priority"
                    label="Conversation"
                    list={priorityList}
                    value={jobFormData.priority}
                    listKey="id"
                    description="name"
                    customOnChange={customJobOnChange}
                  />
                </div>
                <div className={"col-sm-6"}>
                  <label htmlFor="review_text">Rate</label>
                  <div>
                    <Rating
                      size="large"
                      id="stars"
                      name="simple-controlled"
                      value={jobFormData.review_rate}
                      onChange={handleJobInputChange}
                    />{" "}
                  </div>
                </div>
              </div>
            )}

            {!withPayments && (
              <div className="row">
                <div className={"col-sm-6"}>
                  <div className="form-group" style={{ padding: "20px" }}>
                    <input
                      className="form-control-checkbox"
                      id="review"
                      type="checkbox"
                      checked={jobFormData.review == 1}
                      onChange={handleJobInputChange}
                    />
                    <label style={{ paddingLeft: 10 }} htmlFor="review">
                      Show as review
                    </label>
                  </div>
                </div>

                {/* <div className={"col-sm-6"}>
                  <div className="form-group" style={{ padding: "20px" }}>
                    <input
                      className="form-control-checkbox"
                      id="testimonial"
                      type="checkbox"
                      checked={jobFormData.testimonial == 1}
                      onChange={handleJobInputChange}
                    />
                    <label style={{ paddingLeft: 10 }} htmlFor="testimonial">
                      Show as testimonial
                    </label>
                  </div>
                </div> */}
              </div>
            )}

            <div className="row">
              <div className={"col-sm-12 mt-5"}>
                {updateJobLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    className="btn btn-md btn-primary float-end"
                    onClick={saveJobForm}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>

      <Dialog
        fullScreen
        open={statusVisible}
        onClose={handleCloseStatusModal}
        // TransitionComponent={Transition}
        style={{ zIndex: 2235 }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <button
              type="button"
              className="close-modal-button"
              onClick={handleCloseStatusModal}
            >
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">Statuses</h4>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "25px" }}>
          <CustomMuiDataTable
            title={"Statuses"}
            data={statusData}
            columns={statusColumns}
            options={statusOptions}
          />
        </div>
      </Dialog>

      <StatusAddUpdateForm handleClose={handleClose} />

      <Dialog
        fullScreen
        open={paymentsVisible}
        onClose={handleClosePaymentsModal}
        // TransitionComponent={Transition}
        style={{ zIndex: 2235 }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <button
              type="button"
              className="close-modal-button"
              onClick={handleClosePaymentsModal}
            >
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">Payments</h4>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "25px" }}>
          <CustomMuiDataTable
            title={"Payments"}
            data={paymentsData}
            columns={paymentsColumns}
            options={paymentsOptions}
          />
        </div>
      </Dialog>
      <PaymentAddUpdateForm
        id={paymentId}
        handleClose={handleCloseAddUpdatePayment}
        jobId={jobId}
      />
      <CustomFilter
        filterForm={filterForm}
        handleChangeInputFilter={handleChangeInputFilter}
        handleclear={handleclear}
        openDialog={openFilterDialog}
        handleClose={handleCloseFilter}
        handleSave={handleSaveFilter}
      />

      <Dialog
        fullScreen
        open={visibleWorker}
        onClose={handleCloseWorker}
        // TransitionComponent={Transition}
        style={{ zIndex: 2235 }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <button
              type="button"
              className="close-modal-button"
              onClick={handleCloseWorker}
            >
              &times;
            </button>
            <h4 className="text-white mx-3 my-0">
              {formData?.first_name} {formData?.last_name}
            </h4>
          </Toolbar>
        </AppBar>

        <div style={{ padding: "25px" }}>
          <div className="row">
            <div className={"col-sm-6"}>
              <label htmlFor="">Profile image</label>

              <div className="form-group">
                <div className="col-sm-2">
                  <img
                    src={`${process.env.REACT_APP_FILES_PATH}/userProfiles/${formData.profile_image_name}`}
                    className="w-100 mt-3"
                  />
                </div>
              </div>
            </div>

            <div className={"col-sm-6"}>
              <label htmlFor="">ID image</label>

              <div className="col-sm-2">
                <img
                  src={`${process.env.REACT_APP_FILES_PATH}/userProfiles/${formData.id_image_name}`}
                  className="w-100 mt-3"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className={"col-sm-6"}>
              <label htmlFor="email: ">Email : </label>
              <b>{formData.email}</b>
            </div>
          </div>

          <div className="row">
            <div className={"col-sm-6"}>
              <label htmlFor="phone">Phone : </label>
              <b>{formData.phone}</b>
            </div>
            <div className={"col-sm-6"}>
              <label htmlFor="phone">Address : </label>
              <b>{formData.address}</b>
            </div>
          </div>
          <label htmlFor="profile_description">Profile Description : </label>
          <b>{formData.profile_description}</b>
          <div className="row" style={{ marginTop: 20 }}>
            <div className={"col-sm-12"}>
              {selectedcategories?.map((category, index) => (
                <p>{category.name}</p>
              ))}
            </div>
          </div>
          <div className="row">
            {selectedcategories?.map((category, index) => (
              <>
                {!formData.attachments?.some(
                  (x) => x.category_id == category.id
                ) ? (
                  <div style={{ width: "auto" }}></div>
                ) : (
                  <div
                    key={index}
                    className={"col-sm-4"}
                    style={{ position: "relative" }}
                  >
                    <label htmlFor={category.name}>
                      License for {category.name}
                    </label>
                    <div id={category.name}>
                      <object
                        width="100%"
                        height="200"
                        data={
                          formData.attachments.find(
                            (x) => x.category_id == category.id
                          )?.path ??
                          `${process.env.REACT_APP_FILES_PATH}/userFiles/${
                            formData.attachments.find(
                              (x) => x.category?.id == category.id
                            )?.attachment_name
                          }`
                        }
                        type="application/pdf"
                      ></object>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>

          {formData.posts?.map((post, index) => (
            <div className="row" style={{ padding: "20px" }}>
              <div className="profile-post-item" key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div className="profile-post-images">
                  {post.attachments?.map((attachement) => (
                    <div style={{ maxHeight: "300px", position: "relative" }}>
                      {attachement.image_name.includes("mp4") ? (
                        <video controls>
                          <source
                            src={`${process.env.REACT_APP_FILES_PATH}/posts/${attachement.image_name}`}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          style={{ height: "100%" }}
                          src={`${process.env.REACT_APP_FILES_PATH}/posts/${attachement.image_name}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className="profile-post-desc">
                  <p>{post.description}</p>
                </div>
              </div>
            </div>
          ))}

          <div className="row mt-5">
            <div className={"col-sm-6"}>
              <div className="form-group" style={{ padding: "20px" }}>
                <input
                  className="form-control-checkbox"
                  id="active"
                  type="checkbox"
                  checked={formData.active == 1}
                  disabled
                />
                <label style={{ paddingLeft: 10 }} htmlFor="active">
                  Active
                </label>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </AdminLayout>
  );
};
export default AdminJobs;
