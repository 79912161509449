import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications

const JobModal = ({ selectedJobId, closeModal }) => {
  const [mood, setMood] = useState('');

  const handleMoodChange = (e) => {
    setMood(e.target.value);
  };

  const handleSave = async () => {
    if (mood) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API}/admin/jobs/jobs/${selectedJobId}`, { mood });

        if (response.data && response.data.message) {
          toast.success(response.data.message);  
          closeModal();  
        } else {
        }
      } catch (error) {
      }
    } else {
      toast.warning('Please select a mood!'); 
    }
  };

  return (
    <div className="modal-content">
      <h4>Update Customer Mood</h4>
      <select value={mood} onChange={handleMoodChange}>
        <option value=""></option>
        <option value="happy">Happy</option>
        {/* <option value="neutral">Neutral</option> */}
        <option value="sad">Sad</option>
      </select>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default JobModal;
