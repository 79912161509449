import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import PropTypes from "prop-types";
import moment from "moment";

import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";

import { Box, Dialog, Modal } from "@mui/material";
import AdminLayout from "../AdminLayout";
import { useNavigate } from "react-router-dom";
import JobModal from "./Mood";

// const localizer = dayjsLocalizer(dayjs);
const localizer = momentLocalizer(moment);

const JobsCalendar = (props) => {
  let navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const booking_statuses = [
    { id: 0, title: "-" },
    { id: 1, title: "Absent" },
    { id: 2, title: "En retard" },
    { id: 3, title: "Accompli" },
  ];

  const [showPaymentsOnly, setShowPaymentsOnly] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectSlot = (event) => {
    const { start, end } = event;
    let valid = true;

    if (
      selectedDate &&
      selectedDate.setHours(0, 0, 0, 0) === start.setHours(0, 0, 0, 0)
    ) {
      const startDate = start.setHours(0, 0, 0, 0);
      const endDate = end.setHours(0, 0, 0, 0);

      if ((endDate - startDate) / (1000 * 60 * 60 * 24) > 1) return;

      props.bookings.map((booking) => {
        const bookingStart = new Date(booking.start).setHours(0, 0, 0, 0);
        const bookingEnd = new Date(booking.end).setHours(0, 0, 0, 0);

        if (bookingStart < endDate && startDate < bookingEnd) {
          console.log("The date ranges overlap.");
          // valid = false
        } else {
          console.log("The date ranges do not overlap.");
        }
      });
      //
      valid = true;
      //
      if (valid) {
        props.addBooking({
          // start: start.toISOString().slice(0, 10),
          // end: end.toISOString().slice(0, 10),

          start: start
            .toLocaleDateString("pt-br")
            .split("/")
            .reverse()
            .join("-"),

          end: end.toLocaleDateString("pt-br").split("/").reverse().join("-"),
        });
      } else {
        alert("Already reserved");
      }
      console.log("Selected date:", selectedDate);
      // Reset selectedDate state
      setSelectedDate(null);
    } else {
      // First click or different day clicked, update selectedDate state
      setSelectedDate(start);
    }
  };

  const handleSelectEvent = (event) => {
    props.updateBooking(event.id);
  };

  const handleEventClick = (event) => {
    console.log("event", event);
    setSelectedEvent(event);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };

  function convertSqlTimeToAMPM(sqlTime) {
    const timeParts = sqlTime.split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = timeParts[1];

    let meridiem = "AM";
    if (hours >= 12) {
      meridiem = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }

    const transformedTime = `${hours}:${minutes} ${meridiem}`;
    return transformedTime;
  }

  const EventComponent = ({ event }) => {
    const moodEmoji =
      event.mood === "happy"
        ? "😊"
        : event.mood === "neutral"
        ? "😐"
        : event.mood === "sad"
        ? "😢"
        : "";

    return (
      <div className="event-slot">
        <b>
          {moodEmoji} {event.description}{" "}
          {!event.created_at && event.paid == 1
            ? `${event.type == "in" ? " From Client" : " To Worker"}`
            : ""}{" "}
          {!event.created_at && !event.paid
            ? `${event.type == "in" ? " From Client" : " For Worker"}`
            : ""}
        </b>
      </div>
    );
  };

  const deleteBooking = (id) => {
    Swal.fire({
      title: "Veuillez confirmer votre action ?",
      showCancelButton: true,
      confirmButtonText: "delete",
      cancelButtonText: "cancel",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API}/masterData/bookings/${id}`, {
            responseType: "json",
          })

          .then((response) => {
            toast.success(`Success`);
            closeModal();
            props.getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const { max, formats, views, defaultDate, scrollToTime, ...otherProps } =
    useMemo(
      () => ({
        views: {
          day: true,
          month: true,
          agenda: true,
        },
        // views: Object.keys(Views).map((k) => Views[k]),
        formats: {
          // dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd MM/DD', culture),
          timeGutterFormat: "HH:mm",
        },
        // max: dayjs().endOf('day').subtract(1, 'hours').toDate(),

        // start time 8:00am
        min: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          8
        ),

        // end time 5:00pm
        max: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          17
        ),

        defaultDate: new Date(),
        scrollToTime: new Date(1970, 1, 1, 6),
      }),
      []
    );

  const compareDates = (date1, date2) => {
    if (!date1 || !date2) return false;
    if (date1.setHours(0, 0, 0, 0) == date2.setHours(0, 0, 0, 0)) {
      return true;
    } else {
      return false;
    }
  };

  const getDayOfTheWeek = (day) => {
    switch (day.label) {
      case "lun.":
        return "Lundi";
        break;
      case "mar.":
        return "Mardi";
        break;
      case "mer.":
        return "Mercredi";
        break;
      case "jeu.":
        return "Jeudi";
        break;
      case "ven.":
        return "Vendredi";
        break;

      case "sam.":
        return "Samedi";
        break;
      case "dim.":
        return "Dimanche";
        break;
      default:
      // code block
    }
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/jobs/get`, {})
      .then(function (response) {
        setData((prev) => [...prev, ...response.data]);
        getPayments();
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };
  const getPayments = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/jobs/getAllPayments`, {})
      .then(function (response) {
        setData((prev) => [...prev, ...response.data]);

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const style = {
    margin: "auto",
    width: 600,
    height: 350,

    zIndex: 10000,
  };

  const handleJobClick = (jobId) => {
    setSelectedJobId(jobId);
    setModalOpen(true);
  };

  return (
    <AdminLayout>
      <div style={{ height: "80vh" }}>
        <div className="form-group" style={{ padding: "20px" }}>
          <input
            className="form-control-checkbox"
            id="showPaymentsOnly"
            type="checkbox"
            checked={showPaymentsOnly}
            onChange={() => setShowPaymentsOnly((prev) => !prev)}
          />
          <label style={{ paddingLeft: 10 }} htmlFor="paid">
            Show Payments Only
          </label>
        </div>
        <Calendar
          popup
          showAllEvents={true}
          className="custom-calendar"
          defaultDate={defaultDate}
          defaultView={Views.MONTH}
          views={views}
          formats={formats}
          events={data
            .filter((x) => (showPaymentsOnly ? !x.created_at : x))
            .map((e) => ({
              ...e,
              start: new Date(e.status_date),
              end: new Date(e.status_date),
              status_color: e.status_color,
            }))}
          localizer={localizer}
          selected={selectedDate}
          onSelectEvent={handleEventClick}
          onSelectSlot={handleSelectSlot}
          selectable
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: event.status_color || "blue",
              color: "white",
              borderRadius: "0px",
              border: "none",
            };

            if (!event.created_at && event.paid == 1) {
              newStyle.backgroundColor = "green";
            }
            if (!event.created_at && !event.paid) {
              newStyle.backgroundColor = "red";
            }
            return {
              className: "",
              style: newStyle,
            };
          }}
          components={{
            event: EventComponent,
            // month: {
            //   header: (d) => <div>{getDayOfTheWeek(d)}</div>,
            //   dateHeader: (d) => (
            //     <div>
            //       <b>{d.label}</b>
            //     </div>
            //   ),
            // },
          }}
          scrollToTime={scrollToTime}
          // dayPropGetter={(date) => {
          //   const equal = compareDates(date, selectedDate)
          //   if (equal) {
          //     return {
          //       className: 'custom-calendar-selected',
          //     }
          //   }
          //   return {}
          // }}
        />
        <Dialog open={isModalOpen} onClose={closeModal}>
          <Box sx={style}>
            {selectedEvent && (
              <div style={{ padding: 20 }}>
                <b>{selectedEvent.status_date?.toString()}</b>
                <p>
                  {selectedEvent.description}{" "}
                  {selectedEvent.type == "in"
                    ? "From Client"
                    : selectedEvent.type == "out"
                    ? "For Worker"
                    : ""}
                  {!selectedEvent.created_at && selectedEvent.paid == 1
                    ? " : Paid"
                    : ""}{" "}
                  {!selectedEvent.created_at && !selectedEvent.paid
                    ? " : UnPaid"
                    : ""}{" "}
                </p>

                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Worker: </label>
                  </div>
                  <div className={"col-sm-6"}>
                    <a
                      href={`https://darbetm3allem.com/Profile/${selectedEvent.user_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h5>
                        {selectedEvent.user_name} {selectedEvent.user_last_name}
                      </h5>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Customer Name: </label>{" "}
                  </div>{" "}
                  <div className={"col-sm-6"}>
                    {" "}
                    <h5>{selectedEvent.customer_name}</h5>{" "}
                  </div>{" "}
                </div>

                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Customer Phone: </label>{" "}
                  </div>{" "}
                  <div className={"col-sm-6"}>
                    {" "}
                    <h5>{selectedEvent.customer_phone}</h5>{" "}
                  </div>{" "}
                </div>
                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Customer Email: </label>{" "}
                  </div>{" "}
                  <div className={"col-sm-6"}>
                    {" "}
                    <h5>{selectedEvent.customer_email}</h5>{" "}
                  </div>{" "}
                </div>

                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Status: </label>
                  </div>
                  <div className={"col-sm-6"}>
                    <p>{selectedEvent.status_name}</p>{" "}
                  </div>
                </div>

                <div className="row">
                  <div className={"col-sm-6"}>
                    <label>Description: </label>
                  </div>
                  <div className={"col-sm-6"}>
                    <p>{selectedEvent.status_description}</p>{" "}
                  </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                  {selectedEvent.mood && (
                    <div className="col-sm-2">
                      <button onClick={() => handleJobClick(selectedEvent.id)}>
                        {selectedEvent.mood}
                      </button>
                    </div>
                  )}
                  <div className={"col-sm-2"}>
                    <button
                      onClick={() =>
                        navigate("/admin/jobs", {
                          state: {
                            id: selectedEvent.job_id
                              ? selectedEvent.job_id
                              : selectedEvent.id,
                            type: "job",
                          },
                        })
                      }
                    >
                      Edit Job
                    </button>
                  </div>
                  <div className={"col-sm-4"}>
                    <button
                      onClick={() =>
                        navigate("/admin/jobs", {
                          state: {
                            id: selectedEvent.job_id
                              ? selectedEvent.job_id
                              : selectedEvent.id,
                            type: "statuses",
                          },
                        })
                      }
                    >
                      Edit Statuses
                    </button>
                  </div>
                  <div className={"col-sm-4"}>
                    <button
                      onClick={() =>
                        navigate("/admin/jobs", {
                          state: {
                            id: selectedEvent.job_id
                              ? selectedEvent.job_id
                              : selectedEvent.id,
                            type: "payments",
                          },
                        })
                      }
                    >
                      Edit Payments
                    </button>{" "}
                  </div>{" "}
                </div>
              </div>
            )}
          </Box>
        </Dialog>
      </div>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <JobModal selectedJobId={selectedJobId} />
      </Dialog>
    </AdminLayout>
  );
};

export default JobsCalendar;

JobsCalendar.propTypes = {
  bookings: PropTypes.any,
  addBooking: PropTypes.any,
  updateBooking: PropTypes.any,
  event: PropTypes.any,
  events: PropTypes.any,
  getData: PropTypes.any,
};
